<template>
  <div v-if="orderData">
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Order" class="h-100 d-flex flex-wrap table-responsive">
          <table class="table table-bordered ">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.id" @click="hidePopover(orderData.id)">{{ orderData.id }}</span>  

                  <b-popover    :id="'badge'+orderData.id" :target="'badge'+orderData.id" placement="top" triggers="hover click" custom-class="popover-custom">
                    <template >
                      <div @click="copyText(orderData.id)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                    </template>
                  </b-popover>
                </td>
                
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.code" @click="hidePopover(orderData.code)">{{ orderData.code }}</span>  

                      <b-popover    :id="'badge'+orderData.code" :target="'badge'+orderData.code" placement="top" triggers="hover click" custom-class="popover-custom">
                        <template >
                          <div @click="copyText(orderData.code)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                        </template>
                      </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Airway Bill No</th>
                <td class="text-right" v-if="orderData.air_way_bill">

                  <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.air_way_bill.air_way_bill_number" @click="hidePopover(orderData.air_way_bill.air_way_bill_number)">{{ orderData.air_way_bill.air_way_bill_number }}</span>  

                  <b-popover    :id="'badge'+orderData.air_way_bill.air_way_bill_number" :target="'badge'+orderData.air_way_bill.air_way_bill_number" placement="top" triggers="hover click" custom-class="popover-custom">
                    <template >
                      <div @click="copyText(orderData.air_way_bill.air_way_bill_number)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                    </template>
                  </b-popover>

                </td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ orderData.orderstatus.name }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">Type</th>
                <td class="text-right">{{ orderData.type }}</td>
              </tr>

              <tr>
                <th class="text-left">Sales Channel</th>
                <td class="text-right">
                  <b-badge variant="info" v-if="orderData.type == 'cos'">
                    Cash On Site
                  </b-badge>
                  <b-badge variant="info" v-else-if="orderData.is_portal">
                    Portal
                  </b-badge>
                  <b-badge variant="info" v-else> InStore </b-badge>
                </td>
              </tr>

              <tr>
                <th class="text-left">Date</th>
                <td class="text-right">{{ orderData.created_at }}</td>
              </tr>
              <tr v-if="delUser">
                <th class="text-left">Last Update Date</th>
                <td class="text-right">{{ orderData.updated_at }}</td>
              </tr>
              <tr v-else>
                <th class="text-left">Deleted Date</th>
                <td class="text-right">{{ orderData.deleted_at }}</td>
              </tr>
              <tr>
                <th class="text-left">Note</th>
                <td class="text-right">{{ orderData.note }}</td>
              </tr>
              <tr>
                <th class="text-left">Address</th>
                <td class="text-right" dir="rtl">
                   <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.address" @click="hidePopover(orderData.address)">{{ orderData.address }}</span>  
                    
                    <b-popover    :id="'badge'+orderData.address" :target="'badge'+orderData.address" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.address)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Transaction ref</th>
                <td class="text-right" dir="rtl" >
                <span  v-if="orderData.transactions.length  > 0">
                  <span v-b-popover.hover.top.click="''" :id="'badge'+orderData.transactions[0].transaction_ref" @click="hidePopover(orderData.transactions[0].transaction_ref)"> {{
                        orderData.transactions[0].transaction_ref
                    }}
                    
                    <b-popover    :id="'badge'+orderData.transactions[0].transaction_ref" :target="'badge'+orderData.transactions[0].transaction_ref" placement="top" triggers="hover click" custom-class="popover-custom">
                        <template >
                          <div @click="copyText(orderData.transactions[0].transaction_ref)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                        </template>
                      </b-popover>
                    
                    </span>
                </span>

                </td>
              </tr>
              <tr>
                <th class="text-left">Cart Id</th>
                <td class="text-right" dir="rtl">
                  <span  v-if="orderData.transactions.length  > 0">
                  <span v-b-popover.hover.top.click="''" :id="'badge'+orderData.transactions[0].cart_id" @click="hidePopover(orderData.transactions[0].cart_id)">  {{
                        orderData.transactions[0].cart_id
                    }}
                    
                    <b-popover    :id="'badge'+orderData.transactions[0].cart_id" :target="'badge'+orderData.transactions[0].cart_id" placement="top" triggers="hover click" custom-class="popover-custom">
                        <template >
                          <div @click="copyText(orderData.transactions[0].cart_id)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                        </template>
                      </b-popover>
                    
                    </span>
                </span>


                </td>
              </tr>

              <tr v-if="orderData.cart != null">
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl">
                  <b-badge class="badge btn-primary">
                    {{ orderData.cart.payment_gateway_type }}</b-badge>
                </td>
              </tr>
              <tr v-if="orderData.success_date != null">
                <th class="text-left">Success Date</th>
                <td class="text-right" dir="ltr">
                  {{ orderData.success_date }}
                </td>
              </tr>
              <tr v-if="orderData.delivered_date != null">
                <th class="text-left">Delivered Date</th>
                <td class="text-right" dir="ltr">
                  {{ orderData.delivered_date }}
                </td>
              </tr>
              <tr v-if="orderData.completed_date != null">
                <th class="text-left">Completed Date</th>
                <td class="text-right" dir="ltr">
                  {{ orderData.completed_date }}
                </td>
              </tr>

            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-card class="h-100 d-flex flex-wrap" footer-tag="footer">
          <b-button block :to="'/orders/show/invoice/' + orderData.id" variant="outline-primary "
            v-if="authPermissions.includes('show-order-invoice') && orderData.transactions.length != 0" class="mt-1">
            Invoice
          </b-button>
          <div v-if="delUser" class="d-flex flex-wrap justify-content-center">
            <b-button block 
            v-if="
              authPermissions.includes('regenerate-transaction-payment-link') &&
              orderData.orderstatus.slug == 'processing' &&
              orderData.created_by" variant="outline-success " @click="regeneratePaymentLink()" class="mt-1">
              Regenerate Payment Link
            </b-button>
            <b-button block v-if="
              authPermissions.includes('update-processing-status') &&
              orderData.orderstatus.slug == 'processing' || orderData.orderstatus.slug == 'pending'
            " variant="outline-secondary " @click="cancelProcessingOrder(orderData.id)" class="mt-1">
              Cancel Order
            </b-button>
            <b-button block v-if="
              authPermissions.includes('check-order-transaction-data') &&
              orderData.orderstatus.slug == 'processing'
            " variant="outline-warning " @click="checkOrderTransactionData(orderData.id)" class="mt-1">
              Refresh Order Transaction
            </b-button>
            <b-button block v-if="
              authPermissions.includes('show') &&
              orderData.air_way_bill != null 
            " variant="primary " class="mt-1" v-for="(shipPD, index) in airBillitems" :key="index"
              @click="getPDF(shipPD.air_way_bill_number)">
           
              Airway Bill ( {{ shipPD.air_way_bill_number }} )
            </b-button>
           


            <!-- <b-button block v-if="
              authPermissions.includes('show') &&
              orderData.air_way_bill != null && Array.isArray(shipPDF) == false
            " variant="primary " class="mt-1"
              @click="getPDF(shipPDF)">
              Airway Bill
            </b-button> -->


            <b-modal ref="my-modal" ok-only ok-title="Accept" centered size="xl" title="Airway Bill">
              <object>
                <iframe id="pdfID" type="text/html" width="100%" height="1000px" sc :src="shipPDFShow" />
              </object>
            </b-modal>
            <b-button block v-if="
              authPermissions.includes('update-cash-order-status') &&
              orderData.orderstatus.slug == 'pending' &&
              orderData.type == 'cos'
            " variant="primary" class="mt-1" @click="changePortalToDelivered()">
              Update Cash On Site Order
            </b-button>
            <b-button block v-if="
              authPermissions.includes('update-order-status-to-delivered') &&
              orderData.orderstatus.slug != 'shipped' &&
              orderData.orderstatus.slug != 'processing' &&
              orderData.orderstatus.slug != 'delivered' &&
              orderData.type != 'cos' &&
              orderData.is_portal == true
            " @click="changePortalToDelivered()" variant="primary" class="mt-1">
              Update To Delivered
            </b-button>
            <b-button block v-if="
              authPermissions.includes('update-order-status') &&
              orderData.orderstatus.slug != 'delivered' &&
              orderData.orderstatus.slug != 'shipped' &&
              orderData.type != 'cos' &&
              orderData.is_shipping == true
            " :to="{ path: '/orders/Update/Status/' + orderData.id }" variant="primary" class="mt-1">
              Edit status
            </b-button>
            <b-button block v-if="authPermissions.includes('delete')" @click="deleteOrder(orderData.id)"
              variant="outline-danger" class="mt-1">
              Delete
            </b-button>

            <b-button block v-if="
              authPermissions.includes('show-eContract') &&
              orderData.e_contract != null


            " :to="{ path: '/orders/show/' + orderData.id + '/e-contract' }" variant="primary" class="mt-1">
              Show E-Contract
            </b-button>
          </div>

          <!-- Add Regenerate Payment Link Modal -->
          <b-modal @ok="submitGeneratePaymentLink" ref="modal-regenerate-payment-link"
            ok-title="Ok" cancel-title="Close" centered title="Regenerate Payment Link">
            <validation-observer ref="observer">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Wallet Number" label-for="Wallet Number">
                    <validation-provider #default="{ errors }" name="Wallet Number" rules="required">
                      <b-form-input id="wallet_number" v-model="wallet_number" :state="errors.length > 0 ? false : null"
                        placeholder="015XXXXXXXX" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-modal>
          <!-- Add New Order Attachment Modal -->
          <b-modal style="height: 700px !important" id="modal-add-order-attachments" cancel-variant="outline-secondary"
            @ok="storeAttachmentFile" ok-title="Ok" cancel-title="Close" centered title="Add Order Attachment">
            <b-row>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="file_type" rules="required">
                  <b-form-group label="File Type" label-for="file_type" :state="errors.length > 0 ? false : null">
                    <v-select id="file_type" v-model="orderAttachmentData.type"
                      :state="orderAttachmentData.type === null ? false : true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="orderFileAttachmentOptions"
                      :selectable="(option) => !option.includes('select_value')" label="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- file -->
              <b-col md="6">
                <validation-provider #default="{ errors }" name="file" rules="required">
                  <b-form-group label="File" label-for="file">
                    <input type="file" class="form-control-file" name="file" id="file" ref="file" change
                      @change="fileUploadIndex" accept="application/pdf, image/*"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <label for="textarea-default">Note</label>
                <validation-provider #default="{ errors }" name="note" rules="required">
                  <b-form-textarea id="textarea-default" v-model="orderAttachmentData.note" placeholder="Note"
                    :state="errors.length > 0 ? false : null" rows="2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-modal>
          <!-- Update Orer To Delivered-->
          <b-modal style="height: 700px !important" ref="modal-status-update-portal" cancel-variant="outline-secondary"
            @ok="updateOrderStatusToDelivered" ok-title="Ok" cancel-title="Close" centered title="Change Status">
            <b-row>
              <b-col md="12" v-if="orderData.type == 'cos'">
                <validation-provider #default="{ errors }" name="status" rules="required">
                  <b-form-group label="Status" label-for="status" :state="errors.length > 0 ? false : null">
                    <v-select id="status" v-model="cashStatusSelect" :state="cashStatusSelect === null ? false : true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="cashStatusOptions"
                      :selectable="(option) => !option.includes('select_value')" label="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="7" v-if="
                cashStatusSelect == 'delivered' || orderData.type != 'cos'
              ">
                <b-form-group label="File Type" label-for="file_type">
                  <v-select id="file_type" v-model="orderAttachmentData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" />
                </b-form-group>
              </b-col>

              <!-- file -->
              <b-col md="5" v-if="
                cashStatusSelect == 'delivered' || orderData.type != 'cos'
              ">
                <validation-provider #default="{ errors }" name="file" rules="required">
                  <b-form-group label="File" label-for="file">
                    <input type="file" class="form-control-file" name="file" id="file" ref="file" change
                      @change="fileUploadIndex" accept="application/pdf, image/*" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-modal>



          <!-- share  -->
          <b-modal size="lg" id="modal-center" ref="show-share" hide-footer centered @hide="beforeModalClose">
            <b-card title="Order Data" v-if="orderData">
              <b-row>
                <b-col md="4" class="d-flex justify-content-center mb-1">
                  <qr-code :size="200" :text="paymentLink"></qr-code>
                </b-col>
                <b-col md="8">
                  <b-row class="mb-2">
                    <b-col cols="4" class="text-primary font-weight-bold">Code</b-col>
                    <b-col cols="8" class="border border-primary rounded text-primary py-1">{{ orderData.code
                      }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" class="text-primary font-weight-bold">Order Total</b-col>
                    <b-col cols="8" class="border border-primary rounded text-primary py-1">{{
                      orderData.total_paid
                    }}</b-col>
                  </b-row>
            <b-row class="mb-2">
              <b-col cols="4" class="text-primary font-weight-bold">Total For Pay</b-col>
              <b-col cols="8" class="border border-primary rounded text-primary py-1">{{ orderData.total_paid -  orderData.cart.remaining_amount}}</b-col>
            </b-row>
                  <b-row class="mb-2" v-if=" orderData.cart &&  orderData.cart.payment_provider != null">
                    <b-col cols="4" class="text-primary font-weight-bold">Payment Provider</b-col>
                    <b-col cols="8" class="border border-primary rounded text-primary py-1">{{
                      orderData.cart.payment_provider.name
                    }}</b-col>
                  </b-row>


                </b-col>
                <b-col cols="12">
                  <p class="text-primary mb-0">Product Buy Link</p>
                  <div class="d-flex justify-content-start">
                    <p id="myInput" v-if="paymentLink" class="text-primary p-1 mb-0 border border-primary rounded">
                      {{ paymentLink.slice(0, 75) + '...' }}
                    </p>
                    <b-button @click="copyPaymentLink" variant="none">
                      <feather-icon size="20" icon="CopyIcon" /> Copy
                    </b-button>
                  </div>

                  <p class="text-secondary mb-0">
                    This link is valid for 30 minutes only
                  </p>
                </b-col>

                <!-- <b-col cols="12" class="text-center p-1">
            <p class="h3 text-secondary mb-1 font-weight-bold text-center">
              Share link
            </p>
            <b-row>
              <b-col
                class="mb-2"
                v-for="network in networks"
                :key="network.network"
              >
                <ShareNetwork
                  :network="network.network"
                  :style="{ backgroundColor: network.color }"
                  :url="share_link"
                  :title="'MobileMasr'"
                  :description="'بيع مباشر'"
                  class="h-100 w-100"
                >
                  <span class="text-white p-2">{{ network.name }}</span>
                </ShareNetwork>
              </b-col>
            </b-row>
          </b-col> -->
              </b-row>
            </b-card>
          </b-modal>
          <hr />
          <div class="d-flex  justify-content-md-center align-items-center text-center" v-if="orderData.created_by">
            <div class="m-2 w-50">
              <h6 class=" font-weight-bolder">Created By</h6>
              <h3 class="mb-0">
                <b-button pill disabled variant="outline-primary">
                  {{ orderData.created_by.firstname }} {{ orderData.created_by.lastname }}
                </b-button>

              </h3>
            </div>

          </div>
          <div v-if="delUser" class="d-flex  justify-content-md-center align-items-center text-center">
            <div class="m-2 w-50">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ orderData.created_at }}
              </h3>
            </div>
            <div class="m-2 w-50">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ orderData.updated_at }}
              </h3>
            </div>
          </div>
          <div v-else class="d-flex justify-content-md-center align-items-center text-center">
            <div class="m-2">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ orderData.created_at }}
              </h3>
            </div>
            <div class="m-2">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ orderData.deleted_at }}
              </h3>
            </div>
          </div>

          <template #footer v-if="authPermissions.includes('create_refund')">
            <em>
              <b-row>
                <b-col cols="12" v-if="orderData.refund_id != null">
                  <b-button block variant="outline-warning" @click="
                    $router.push('/orders/refund/show/' + orderData.refund_id)
                    ">
                    Show Refund Data
                  </b-button>
                </b-col>
              </b-row>
            </em>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="12" md="6" class="mb-1">
        <b-card v-if="orderData.vendor != null" title="Vendor" class="h-100 cardH d-flex flex-wrap table-responsive">
          <a class="ml-1 position-absolute" style="top: 20px; right: 25px" :href="'/Vendor/Show/' + orderData.vendor.id"
            v-if="authPermissions.includes('vendor')">
            <img :src="require('@/assets/images/icons/show.svg')" />
          </a>
          <!-- User Avatar & Action Buttons -->
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ orderData.vendor.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">{{ orderData.vendor.firstname }}</td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">{{ orderData.vendor.lastname }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">
                
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.vendor.email" @click="hidePopover(orderData.vendor.email)">{{ orderData.vendor.email }}</span>  
                    
                    <b-popover    :id="'badge'+orderData.vendor.email" :target="'badge'+orderData.vendor.email" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.vendor.email)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone</th>
                <td class="text-right">
                  <a :href="'tel:' + orderData.vendor.phone" class="h4" v-b-popover.hover.top.click="''" :id="'badge'+orderData.vendor.phone" @click="hidePopover(orderData.vendor.phone)">
                  <span>{{ orderData.vendor.phone }}</span>  
                  </a>
                  <b-popover    :id="'badge'+orderData.vendor.phone" :target="'badge'+orderData.vendor.phone" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.vendor.phone)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Store Name</th>
                <td class="text-right">
                
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.vendor.storename" @click="hidePopover(orderData.vendor.storename)">{{ orderData.vendor.storename }}</span>  
                    
                    <b-popover    :id="'badge'+orderData.vendor.storename" :target="'badge'+orderData.vendor.storename" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.vendor.storename)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <b-card v-else title="Vendor" class="h-100 cardH text-center d-flex flex-wrap"
          style="display: flex; justify-content: center; align-items: center">
          <h1>Mobile Masr</h1>
        </b-card>
      </b-col>
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="User" class="h-100 d-flex flex-wrap table-responsive">

          <a class="ml-1 position-absolute" style="top: 20px; right: 25px" :href="'/User/Show/' + orderData.user.id"
            v-if="authPermissions.includes('user')">
            <img :src="require('@/assets/images/icons/show.svg')" />
          </a>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ orderData.user.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">{{ orderData.user.firstname }}</td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">{{ orderData.user.lastname }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">
                  <span  v-b-popover.hover.top.click="''" :id="'badge'+orderData.user.email" @click="hidePopover(orderData.user.email)">{{ orderData.user.email }}</span>  
                    
                    <b-popover    :id="'badge'+orderData.user.email" :target="'badge'+orderData.user.email" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.user.email)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone 1</th>

                <td class="text-right">
                  <a :href="'tel:' + orderData.phoneone" class="h4"   v-b-popover.hover.top.click="''" :id="'badge'+orderData.phoneone" @click="hidePopover(orderData.phoneone)">
                    <the-mask mask="#### ### ####" disabled v-model="orderData.phoneone"
                      class="border-0 text-right phonenumber">
                    </the-mask>

                    
                    <b-popover    :id="'badge'+orderData.phoneone" :target="'badge'+orderData.phoneone" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.phoneone)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

                  </a>
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone 2</th>

                <td class="text-right">
                  <a :href="'tel:' + orderData.phonetwo" class="h4"  v-b-popover.hover.top.click="''" :id="'badge'+orderData.phonetwo" @click="hidePopover(orderData.phonetwo)">
                    <the-mask mask="#### ### ####" disabled v-model="orderData.phonetwo"
                      class="border-0 text-right phonenumber">
                    </the-mask>

                    <b-popover    :id="'badge'+orderData.phonetwo" :target="'badge'+orderData.phonetwo" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(orderData.phonetwo)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col class="mb-1" md="12">
        <b-row>
          <!-- EContract Data -->
          <b-col class="mb-1" md="6">
            <b-card title="Customer Details" class="h-100 d-flex flex-wrap table-responsive">
              <a class="ml-1 position-absolute" style="top: 20px; right: 25px" @click="enableUpdateCustomerDataAction()"
                v-if="authPermissions.includes('update-eContract-data')">
                <img :src="require('@/assets/images/icons/edit-new.svg')" />
              </a>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="text-left">Customer Name</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      {{ orderData.e_contract.customer_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Customer Id Number</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      {{ orderData.e_contract.customer_id_number }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Id Front Image</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      <a v-if="orderData.e_contract.id_front_image != null" :href="orderData.e_contract.id_front_image"
                        target="_blank" rel="noopener noreferrer">Open</a>

                      <!-- <a
                        class="ml-1"
                        @click="
                          enableUpdateIdFrontImage = !enableUpdateIdFrontImage
                        "
                        v-if="authPermissions.includes('update-eContract-data')"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px"
                        />
                      </a> -->
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Id Back Image</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      <a v-if="orderData.e_contract.id_back_image != null" :href="orderData.e_contract.id_back_image"
                        target="_blank" rel="noopener noreferrer">Open</a>

                      <!-- <a
                        class="ml-1"
                        @click="
                          enableUpdateIdBackImage = !enableUpdateIdBackImage
                        "
                        v-if="authPermissions.includes('update-eContract-data')"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px"
                        />
                      </a> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="orderData.e_contract != null">
                <b-button v-if="orderData.e_contract.e_contract_image != null" block v-b-modal.eContractImage
                  variant="primary" class="mr-1 mt-1">
                  EContract Image
                </b-button>
                <b-modal id="eContractImage" ok-only centered size="lg" title="EContract Image">
                  <img width="100%" :src="orderData.e_contract.e_contract_image" />
                </b-modal>
              </div>
            </b-card>
          </b-col>
          <!--Edit EContract Data -->
          <b-col class="mb-1" md="6" v-if="
            (enableUpdateIdFrontImage ||
              enableUpdateIdBackImage ||
              enableUpdateCustomerData) &&
            authPermissions.includes('update-eContract-data')
          ">
            <b-card title="Update Customer Data" class="table-responsive" v-if="
              (enableUpdateIdFrontImage ||
                enableUpdateIdBackImage ||
                enableUpdateCustomerData) &&
              authPermissions.includes('update-eContract-data')
            ">
              <validation-observer ref="simpleRules">
                <b-row>
                  <!-- Customer name -->
                  <b-col md="6">
                    <b-form-group label="Customer Name" label-for="first-name" v-if="enableUpdateCustomerData">
                      <validation-provider #default="{ errors }" name="Customer Name" rules="required">
                        <b-form-input id="first-name" v-model="customerData.customer_name" placeholder="John"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Customer Id Number -->
                  <b-col md="6">
                    <b-form-group v-if="enableUpdateCustomerData" label="Customer Id Number" label-for="customer-id">
                      <validation-provider #default="{ errors }" name="Customer Id Number" rules="required">
                        <b-form-input id="customer-id" v-model="customerData.customer_id_number"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Id front picture -->
                  <b-col md="6" v-if="enableUpdateIdFrontImage || enableUpdateCustomerData">
                    <b-form-group label="Id front picture" label-for="Id front picture">
                      <validation-provider #default="{ errors }" name="Id front picture">
                        <b-form-file @change="ChangeIDFrontImage($event)" v-model="customerData.id_front_image_file"
                          id="ProfilePictureBase642" accept="image/*" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Id back picture -->
                  <b-col md="6" v-if="enableUpdateIdBackImage || enableUpdateCustomerData">
                    <b-form-group label="Id back picture" label-for="Id back picture">
                      <validation-provider #default="{ errors }" name="Id back picture">
                        <b-form-file @change="ChangeIDBackImage($event)" v-model="customerData.id_back_image_file"
                          id="ProfilePictureBase643" accept="image/*" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-button variant="success" @click="updateEcontractIds" block>
                    Submit</b-button>
                </b-row>
              </validation-observer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- variants data -->
    <b-row>
      <b-col v-if="orderData.variants.length > 0" class="" cols="12">
        <b-card title="Items List" class="table-responsive">
          <b-table striped hover :items="orderData.variants" :fields="fields" class="">


            <template #cell(name)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  
                    
                    <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

            </template>

            <template #cell(sku)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  
                    
                    <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

            </template>


            <template #cell(show)="items">
              <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px" class="btn-icon rounded-circle"
                :to="{
                  path:
                    (items.item.is_new ? '/New' : '/Used') +
                    '/ShowProduct/variant/show/' +
                    items.item.id,
                }" variant="success">
                <feather-icon style="width: 18px; height: 18px" icon="EyeIcon" />
              </b-button>
              <!--:to="{name: 'OrderShowCompareReports', params: { selling: items.value[0].type == 'selling' ? items.value[0].serial_number : items.value[1].serial_number, buying: items.value[1].type == 'buying' ? items.value[1].serial_number : items.value[0].serial_number} }"-->

              <!-- <h1 v-else>-</h1> -->
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(is_new)="data">
              <b-badge :variant="data.value ? 'success' : 'info'">
                {{ data.value ? 'New' : 'Used' }}
              </b-badge>
            </template>
            <template #cell(identical)="data">
              <img :src="data.value == false
                ? require('@/assets/images/remove.png')
                : require('@/assets/images/check.png')
                " alt="alternate" title="tit" width="20px" height="20px" />
            </template>
          </b-table>
        </b-card>
        <b-modal size="lg" id="modal-scrollable" scrollable
          :title="'Compare Result - (Identical: ' + identicalType + ')'" cancel-title="Close"
          cancel-variant="outline-secondary" class="d-flex flex-wrap">
          <b-table striped hover :items="compareItems" :fields="compareFields">
            <template #cell(identical)="data">
              <img :src="data.value == false
                ? require('@/assets/images/remove.png')
                : require('@/assets/images/check.png')
                " alt="alternate" title="tit" width="20px" height="20px" />
            </template>
          </b-table>
        </b-modal>
      </b-col>
      <b-col v-if="orderData.bundles.length > 0" cols="12">
        <b-card title="Bundles">
          <b-table-simple hover small responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="4">Bundle</b-th>
                <b-th colspan="1">Bundle Qty</b-th>
                <b-th colspan="1">Status</b-th>
                <b-th colspan="5">Item</b-th>
                <b-th colspan="1">Item Qty</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in orderData.bundles" :key="index">
              <b-tr>
                <b-th colspan="4">
                  {{ item.title }}
                </b-th>
                <b-td colspan="1">
                  {{ item.quantity }}
                </b-td>
                <b-td colspan="1">
                  <b-badge variant="primary">
                    {{ item.item_status_slug }}
                  </b-badge>
                </b-td>
                <b-th colspan="5">
                  <div v-for="(itme, index) in item.variants" :key="index">
                    {{ itme.name }}
                  </div>
                </b-th>
                <b-td colspan="1">
                  <div v-for="(itme, index) in item.variants" :key="index">
                    {{ itme.quantity }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    <!-- princing data -->
    <b-row>
      <b-col cols="12" md="12">
        <b-card title="Order Total" class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Total</th>
                <th class="text-right">{{ orderData.total }}</th>
              </tr>
              <tr>
                <th class="text-left">Shipping Price</th>
                <td class="text-right">{{ orderData.shipping_price }}</td>
              </tr>
              <tr>
                <th class="text-left">Tax</th>
                <td class="text-right">{{ orderData.tax }}</td>
              </tr>
              <tr>
                <th class="text-left">COD</th>
                <td class="text-right">{{ orderData.cod }}</td>
              </tr>

              <tr>
                <th class="text-left">Discounte</th>
                <td class="text-right">{{ discount }}</td>
              </tr>

              <tr class="bg-secondary text-light">
                <th class="text-left">Total Paid</th>
                <th class="text-right">{{ orderData.total_paid }}</th>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Transactions">
          <b-table id="TransactionsTable" ref="admint" striped hover responsive show-empty class="position-relative"
            :items="orderData.transactions" :fields="fieldsTransactions">

            
            <template #cell(code)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge'+data.value" @click="hidePopover(data.value)" >{{ data.value }}</span>  
                    
                    <b-popover    :id="'badge'+data.value" :target="'badge'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

            </template>

            
            <template #cell(transaction_ref)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge2'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  
                    
                    <b-popover    :id="'badge2'+data.value" :target="'badge2'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

            </template>


            <template #cell(status)="data">
              <b-badge variant="primary">{{ data.value }}</b-badge>
            </template>
            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <!-- show payout -->
                <b-button style="padding: 7px; margin: 6px; width: 35px; height: 35px" class="btn-icon rounded-circle"
                  v-if="authPermissions.includes('show')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success" type="submit" :to="{ path: '/transaction/show/' + items.item.id }">
                  <feather-icon icon="EyeIcon" style="width: 18px; height: 18px" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card title="Order Attachments">
          <b-button v-if="authPermissions.includes('add-order-attachment')" style="top: 10px; right: 20px"
            class="position-absolute" target="_blank" variant="none" type="submit"
            v-b-modal.modal-add-order-attachments>
            <img :src="require('@/assets/images/icons/add-new.svg')" />
          </b-button>

          <b-alert v-height-fade.appear fade :show="storeAttachmentShowDismissibleAlert"
            @dismissed="storeAttachmentShowDismissibleAlert = false" variant="danger">
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
              <ul v-for="(values, index) in storeAttachmentErrorsBack" :key="index">
                <li v-for="(value, index) in values" :key="index">
                  {{ value }}
                </li>
              </ul>
            </div>
          </b-alert>

          <b-table v-if="orderData.order_attachments.length != 0" striped hover :items="orderData.order_attachments"
            :fields="orderAttachmentFields" class="">
            <!-- <template #cell(name)="items">
                  <a       :href="items.item.name"
                      target="_blank">

                    <img
                      style="width: 24px;height: 21px;"
                
                      :src="require('@/assets/images/icons/photo-up.svg')"
                    />
                  </a>
                </template> -->
            <template #cell(actions)="items">
              <div class="d-flex">
                <!-- delete Order -->
                <b-button :href="items.item.name" target="_blank" variant="none" type="submit">
                  <img :src="require('@/assets/images/icons/photo-up.svg')" />
                </b-button>
                <!-- <b-button
                      v-if="authPermissions.includes('delete')"
                      variant="none"
                      type="submit"
                      @click="deleteAttachmentFile(items.item.id)"
                    >
                      <img
                        :src="require('@/assets/images/icons/delete-new.svg')"
               
                      />
              
                    </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-col md="12">
      <b-card title="Airway Bills">
        <b-table id="OrdersTable" ref="admint" striped responsive :items="airBillitems" :fields="airBillfields"
          class="position-relative">
          <template #cell(status)="data">
            <b-badge variant="primary">
              {{ data.value }}
            </b-badge>
          </template>
          

       
          <template #cell(air_way_bill_number)="data">
              <span  v-b-popover.hover.top.click="''" :id="'badge2'+data.value" @click="hidePopover(data.value)">{{ data.value }}</span>  
                    
                    <b-popover    :id="'badge2'+data.value" :target="'badge2'+data.value" placement="top" triggers="hover click" custom-class="popover-custom">
                      <template >
                        <div @click="copyText(data.value)" class="cursor-pointer">   <feather-icon size="20" icon="CopyIcon" class="text-primary" />  Copy</div>
                      </template>
                    </b-popover>

            </template>


          <template #cell(order_code)="data">
            <router-link :to="{ path: '/orders/show/' + data.item.order_id }" v-if="authPermissions.includes('order')">

              {{ data.value }}

            </router-link>


          </template>

          <template #cell(actions)="items">
            <div class="d-flex p-1">
              <b-button v-if="authPermissions.includes('air-way-bill')" variant="success" type="submit"
                class="my-1 mx-0 mr-1 py-1 px-1" :to="{ path: '/AirWayBill/show/' + items.item.id }">
                <feather-icon style="width: 18px; height: 18px;" class="d-flex align-center" icon="EyeIcon" />
              </b-button>

            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>

    <!-- AWB Logger Data -->
    <b-card v-if="shipData != null" title="Airway Bills Status" class="d-flex flex-wrap">
      <b-row>
        <b-col cols="12">
          <b-tabs>
            <b-tab v-for="(item, index) in shipData" :key="index" :title="'Bill: ' + item.AirWayBillNo">
              <app-timeline class="pl-4 pt-2">
                <app-timeline-item v-for="(itemDetails, index) in item.TrackingLogDetails" :key="index"
                  variant="primary">
                  <h5>{{ itemDetails.Remarks }}</h5>
                  <p>{{ itemDetails.ActivityDate }}</p>
                </app-timeline-item>
              </app-timeline>
              <hr />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <!-- Order Logger store_order_logger-->
    <b-card title="Notes" class="d-flex flex-wrap">
      <b-row>
        <b-col cols="12" v-if="authPermissions.includes('store_order_logger') ">
          <label for="textarea-default">Note</label>
          <b-form-textarea id="textarea-default" v-model="loggerNote.note" placeholder="Note" rows="3" />
          <b-button variant="primary" class="mt-1" @click="storeLogger(orderData.id)">
            Submit
          </b-button>
        </b-col>
        <b-col class="mt-3" cols="12"  >
          <app-timeline>
            <app-timeline-item v-for="(item, index) in orderData.logers" :key="index"
              :variant="item.admin != null ? 'primary' : 'success'">
              <div class="d-flex flex-sm-row flex-column flex-nowrap justify-content-between mb-1 mb-sm-0 text-wrap">
                <h6>{{ item.note }}</h6>
              </div>
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-else>
                <template #aside>
                  <!-- <b-avatar :src="item.vendor.profile_picture" /> -->
                </template>
                <!-- <h6>{{item.vendor.storename}}</h6> -->
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { TheMask } from 'vue-the-mask'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes',
      },
    ],
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    TheMask,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      required,
      authPermissions: [],
      orderData: null,
      errors_back: [],
      storeAttachmentErrorsBack: [],
      storeAttachmentShowDismissibleAlert: false,
      showDismissibleAlert: false,
      id: 0,
      status: '',
      area: '',
      delUser: true,
      imgIndex: null,
      profileArr: [],
      paymentLink: null,

      loggerNote: {
        note: '',
      },
      discount: '',

      compareItems: [],
      compareFields: [
        {
          key: 'name',
          label: 'name',
        },
        {
          key: 'selling',
          label: 'selling',
        },
        {
          key: 'buying',
          label: 'buying',
        },
        {
          key: 'identical',
          label: 'identical',
        },
      ],
      fields: [
        {
          key: 'name',
          label: 'Product',
        },
        {
          key: 'sku',
          label: 'SKU',
        },
        {
          key: 'quantity',
          label: 'Qty',
        },
        {
          key: 'vendor_price',
          label: 'Vendor Price',
        },
        {
          key: 'margin',
          label: 'margin',
        },
        {
          key: 'insurance_fees',
          label: 'Insurance Fees',
        },
        {
          key: 'price',
          label: 'Price',
        },

        {
          key: 'total',
          label: 'Total',
        },

        {
          key: 'item_status_slug',
          label: 'Item Status In Order',
        },
        {
          key: 'is_new',
          label: 'Type',
        },
        // {
        //   key: 'identical',
        //   label: 'identical'
        // },
        // {
        //   key: 'CompareReports',
        //   label: 'Compare Reports'
        // }
        // },
        {
          key: 'show',
          label: 'show',
        },
      ],

      // table fields
      airBillfields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'air_way_bill_number',
          label: 'AirWayBill Number',
        },

        {
          key: 'order.code',
          label: 'Order Code',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'pickup_request_number',
          label: 'Pickup Request Number',
        },

        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      orderAttachmentFields: [
        {
          key: 'type',
          label: 'Attachment Type',
        },
        // {
        //   key: 'name',
        //   label: 'File',
        // },

        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      // table fildes
      fieldsTransactions: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'order.code',
          label: 'status',
        },
        {
          key: 'transaction_ref',
          label: 'transaction ref',
        },
        {
          key: 'platform_type',
          label: 'Platform Type',
        },
        {
          key: 'customer_name',
          label: 'customer name',
        },

        {
          key: 'customer_data',
          label: 'customer (email/phone)',
        },

        {
          key: 'transaction_type',
          label: 'transaction type',
        },
        {
          key: 'payment_method',
          label: 'payment method',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'remaining_amount',
          label: 'remaining amount',
        },
        {
          key: 'down_payment_method',
          label: 'down_payment_method',
        },
        {
          key: 'payment_commission',
          label: 'payment commission',
        },
        {
          key: 'transaction_fees',
          label: 'transaction fees',
        },
        {
          key: 'created_at',
          label: 'created at',
        },
        {
          key: 'updated_at',
          label: 'updated at',
        },
        {
          key: 'actions',
        },
      ],
      itemsTransactions: [],
      identicalType: false,
      shipData: null,
      shipPDF: null,
      shipPDFShow: null,
      cashStatusOptions: ['delivered', 'canceled'],
      orderFileAttachmentOptions: [
        'invoice',
        'agreement',
        'econtract',
        'insurance',
        'airwaybill',
        'signed_airwaybill',
        'refund_airwaybill',
      ],
      isConfirming: false,
      wallet_number:null,
      cashStatusSelect: null,
      customerData: {
        customer_name: null,
        customer_id_number: null,
        id_front_image: null,
        id_back_image: null,
        id_front_image_file: null,
        id_back_image_file: null,
      },
      enableUpdateCustomerData: false,
      enableUpdateIdFrontImage: false,
      enableUpdateIdBackImage: false,
      orderAttachmentData: {
        file: null,
        type: null,
        note: null,
      },
      airBillitems: []
    }
  },

  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    beforeModalClose(evt) {
      // If already confirming, let the modal close without asking again
      if (this.isConfirming) {
        this.isConfirming = false; // Reset the flag for future closings
        return;
      }
      evt.preventDefault();
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to close the modal?', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.isConfirming = true;
            // If the user confirms, manually hide the modal
            this.$bvModal.hide('modal-center');

          }


        })
    },


    copyPaymentLink() {
      // let text = document.getElementById('myText').innerHTML;

      navigator.clipboard.writeText(this.paymentLink)
      this.$swal({
        position: 'center',
        icon: 'success',
        title: 'Copied ',
        showConfirmButton: false,
        timer: 1500,
      })
    },
    regeneratePaymentLink() {
      if (this.orderData.cart.payment_provider.code == 'mobile-wallet') {

        this.$refs['modal-regenerate-payment-link'].show()
      } else {
        this.submitGeneratePaymentLink()
      }
    },
    submitGeneratePaymentLink() {
      //console.log('delete')
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want regenerate the payment transaction link', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.showDismissibleAlert = false
            axios
              .post('orders/' + this.orderData.id + '/payment-link-regenerate', {
                cart_id: this.orderData.cart.id,
                wallet_number: this.wallet_number
              })
              .then((result) => {

                this.paymentLink = result.data.data.redirect_url
                this.$refs['show-share'].show()


                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })



              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })

    },
    getBillData() {
    const air_way_bill =  Array.isArray(this.orderData.air_way_bill)
                ? this.orderData.air_way_bill
                : [this.orderData.air_way_bill];
      const airWayBillNumbers =  air_way_bill.map(item => item.air_way_bill_number);

      axios
        .post(
          'orders/awb/tracking/get', { air_way_bill_numbers: airWayBillNumbers })
        .then((result) => {
          this.shipData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    changePortalToDelivered() {
    if(this.orderData.is_shipping){

      this.orderAttachmentData.type = 'signed_airwaybill'
    }else{
      this.orderAttachmentData.type = 'delivered_attachment'

    }
      this.$refs['modal-status-update-portal'].show()
    },
    showAirWayBill(ReportDoc) {
      if (this.shipPDFShow == null) {
        this.shipPDFShow = 'data:application/pdf;base64,' + ReportDoc
        // this.getPDF()
        this.showModal()

      } else {
        this.showModal()
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    async getPDF(way_bill_number) {
      // Use a cache to store PDFs by way_bill_number
      if (!this.pdfCache) {
        this.pdfCache = {};
      }

      // Check if the PDF for the given way_bill_number is already cached
      if (this.pdfCache[way_bill_number]) {
        this.shipPDFShow = this.pdfCache[way_bill_number];
        this.showModal();
        return;
      }

      // If not cached, fetch the PDF
      try {
        const result = await axios.get(`orders/awb/${way_bill_number}/pdf/generate`);

        this.shipPDF = result.data.data;

        if (this.shipPDF && this.shipPDF.ReportDoc) {
          this.shipPDFShow = 'data:application/pdf;base64,' + this.shipPDF.ReportDoc;

          // Save to cache
          this.pdfCache[way_bill_number] = this.shipPDFShow;

          this.showModal();
        }
      } catch (err) {
        this.errors_back.length = 0;

        if (err.response?.data?.data) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({ error: err.response.data.data });
          } else {
            this.errors_back = err.response.data.data;
          }
        } else {
          this.errors_back.push({ error: 'Internal server error' });
        }

        this.showDismissibleAlert = true;
      }
    },
    async cancelProcessingOrder() {
      await this.$bvModal
        .msgBoxConfirm('Please confirm that you want to cancel Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                'orders/' +
                this.orderData.id +
                '/changeProcessingOrderToCanceled'
              )
              .then((result) => {
                this.orderData = result.data.data
                if (this.orderData.deleted_at != null) {
                  this.delUser = false
                } else {
                  this.delUser = true
                }
              })
              .catch((err) => {
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    async checkOrderTransactionData() {
      await this.$bvModal
        .msgBoxConfirm('Please confirm that you want to Reresh Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.errors_back.length = 0
            this.showDismissibleAlert = false

            axios
              .get(
                'orders/' +
                this.orderData.id +
                '/checkProcessingOrderTransaction'
              )
              .then((result) => {
                this.orderData = result.data.data
                // //console.log(this.orderData)
                if (this.orderData.air_way_bill != null) {
                  this.getBillData()
                  // this.getPDF()
                }
                if (this.orderData.deleted_at != null) {
                  this.delUser = false
                } else {
                  this.delUser = true
                }

                this.orderData.orderstatus = result.data.data.orderstatus
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Order Data Checked Success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })

              .catch((err) => {
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    validateOrderAttachmentData() {
      if (this.orderAttachmentData.file == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'must upload attachment file ',
        })
        return
      }
      if (this.orderAttachmentData.type == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'invalid selected type ',
        })
        return
      }
    },
    // store function of (store button) Order
    async storeAttachmentFile() {
      this.storeAttachmentErrorsBack = []
      this.storeAttachmentShowDismissibleAlert = false
      let formData = new FormData()
      await this.validateOrderAttachmentData()

      formData.append('note', this.orderAttachmentData.note)
      formData.append('type', this.orderAttachmentData.type)
      formData.append('file', this.orderAttachmentData.file)

      axios
        .post('orders-attachments/' + this.orderData.id + '/store', formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.orderData.order_attachments.push(result.data.data)
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.storeAttachmentErrorsBack.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.storeAttachmentErrorsBack.push({
                error: err.response.data.data,
              })
            } else {
              this.storeAttachmentErrorsBack = err.response.data.data
            }
            this.storeAttachmentShowDismissibleAlert = true
          } else {
            this.storeAttachmentErrorsBack = []
            this.storeAttachmentErrorsBack.push({
              error: 'internal server error',
            })
            this.storeAttachmentShowDismissibleAlert = true
          }
        })
    },
    // delete function of (delete button) Order
    deleteAttachmentFile(id) {
      //console.log('delete')
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete This File.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('orders-attachments/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    updateCashOnSiteStatus() {
      let formData = new FormData()
      if (this.cashStatusSelect == 'delivered') {
        if (this.orderAttachmentData.file == null) {
          this.showDismissibleAlert = true
          this.errors_back.push({
            error: 'must upload attachment file ',
          })
          return
        }
        if (this.orderAttachmentData.type == null) {
          this.showDismissibleAlert = true
          this.errors_back.push({
            error: 'invalid selected type ',
          })
          return
        }
        formData.append('type', this.orderAttachmentData.type)
        formData.append('file', this.orderAttachmentData.file)
        // data.file = this.orderAttachmentData.file
        console.table(Object.fromEntries(formData))
      }
      axios
        .post(
          'orders/' +
          this.id +
          '/cashOnSite/' +
          this.cashStatusSelect +
          '/update-status',
          formData
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log(result.data.data)
          this.orderData = result.data.data
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    updateOrderStatusToDelivered() {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to Update Order To Delivered.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            let formData = new FormData()
            if (this.orderAttachmentData.file == null) {
              this.showDismissibleAlert = true
              this.errors_back.push({
                error: 'must upload attachment file ',
              })
              return
            }
            if (this.orderAttachmentData.type == null) {
              this.showDismissibleAlert = true
              this.errors_back.push({
                error: 'invalid selected type ',
              })
            }

            formData.append('type', this.orderAttachmentData.type)
            formData.append('file', this.orderAttachmentData.file)
            // data.file = this.orderAttachmentData.file
            console.table(Object.fromEntries(formData))

            var url = null

            if (this.orderData.type == 'cos' || this.orderData.type == 'cos' ) {
              url =
                'orders/' +
                this.id +
                '/cashOnSite/' +
                this.cashStatusSelect +
                '/update-status'
            } else {
              url = 'orders/' + this.id + '/ChangeToDelivered'
            }
            axios
              .post(url, formData)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.orderData = result.data.data
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    fetchData() {
      axios
        .get('orders/' + this.id)
        .then((result) => {
          this.orderData = result.data.data;

          if (this.orderData.air_way_bill != null) {
            this.airBillitems = Array.isArray(this.orderData.air_way_bill)
                ? this.orderData.air_way_bill
                : [this.orderData.air_way_bill];
          }

          // //console.log(this.orderData)
          if (this.orderData.air_way_bill != null) {
            this.getBillData()
            this.shipPDF = this.orderData.air_way_bill.air_way_bill_pdf
            // this.getPDF()
          }
          if (this.orderData.deleted_at != null) {
            this.delUser = false
          } else {
            this.delUser = true
          }


          //
          if (this.orderData.coupon != null) {
            this.discount = this.orderData.coupon.percentage
          } else {
            this.discount = '-'
          }

          // this.getPDF();
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    selectRow(index, identical) {
      this.identicalType = identical
      this.compareItems = []
      for (let inde in this.orderData.variants[index].compareResult) {
        this.compareItems.push({
          name: Object.keys(
            this.orderData.variants[index].compareResult[inde]
          )[0],
          selling:
            this.orderData.variants[index].compareResult[inde][
              Object.keys(this.orderData.variants[index].compareResult[inde])[0]
            ].selling,
          buying:
            this.orderData.variants[index].compareResult[inde][
              Object.keys(this.orderData.variants[index].compareResult[inde])[0]
            ].buying,
          identical:
            this.orderData.variants[index].compareResult[inde][
              Object.keys(this.orderData.variants[index].compareResult[inde])[0]
            ].identical,
        })
      }
    },
    storeLogger(id) {
      axios
        .post('orders/' + id + '/logger/store', this.loggerNote)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    enableUpdateCustomerDataAction() {
      this.enableUpdateCustomerData = !this.enableUpdateCustomerData
      if (
        this.enableUpdateCustomerData == true &&
        this.orderData.e_contract != null
      ) {
        this.customerData.customer_id_number =
          this.orderData.e_contract.customer_id_number
        this.customerData.customer_name =
          this.orderData.e_contract.customer_name
      }
    },
    ChangeIDFrontImage(e) {
      // this.id_front_image_file = e
      const image = e.target.files[0]
      if (e != null && image != null) {
        var fileReader = new FileReader()
        let vm = this
        fileReader.onload = (e) => {
          this.customerData.id_front_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
          // console.log(this.id_front_image)
        }
        fileReader.readAsDataURL(image)
      }
    },
    ChangeIDBackImage(e) {
      // this.id_back_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.customerData.id_back_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },

    updateEcontractIds() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('orders/' + this.id + '/e-contract/update', {
              id_front_image: this.customerData.id_front_image,
              id_back_image: this.customerData.id_back_image,
              customer_id_number: this.customerData.customer_id_number,
              customer_name: this.customerData.customer_name,
            })
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.orderData.e_contract = result.data.data
              this.enableUpdateCustomerData = false
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    deleteOrder(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('orders/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    copyText(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$bvToast.toast('copied to clipboard!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          });
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          this.$bvToast.toast('Failed to copy IMEI.', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        });
    },
    hidePopover(orderId) {
    this.$root.$emit('bv::hide::popover', `badge${orderId}`);
    },
    fileUploadIndex(e) {
      this.orderAttachmentData.file = e.target.files[0]
    },
  },
}
</script>

<style scoped>
/* #card {
    display: flex !important;
    flex-wrap: wrap !important;
} */
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}

#table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.cardH {
  margin-bottom: 0px;
}

.phonenumber {
  font-weight: bolder !important;
  color: #0a0a0a;
  background: none;
}
.text-wrap {
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Ensure proper break */
  overflow-wrap: break-word; /* Consistent behavior across browsers */
  white-space: normal; /* Allow wrapping */
}
</style>
